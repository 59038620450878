<template>
<div id="Mine">  
  <Header/>
  <CommonAlert2Func ref="commonAlert2Func" :title="title"  :content="content" :btnnameup="btnNameUp" :btnnamedown="btnNameDown" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>
  <body class=" bg-white">
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
          <a class="navbar-brand text-bold me-0 col-4 text-card-big" rel="tooltip" data-placement="bottom" >
          我的
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" @click="onSetting">
            <img src="assets/img/ic_nav_setting.png" class="avatar avatar-sm">
        </a> 
      </div>
    </nav>   
    <div class="nicolespace"></div>
  <!-- START Blogs w/ 4 cards w/ image & text & link -->
    <div class="container">
    <div class="position-relative">
        <img class="w-100 d-md-none " :src="($GlobalData.uid != '' && !isMonthlyUser)? 'assets/img/img_me_member-phone.png':'assets/img/img_me_member-no-phone.png'">
        <img class="w-100 d-none d-md-block " :src="($GlobalData.uid != '' && !isMonthlyUser)? 'assets/img/img_me_membervip_card_pad.png' : 'assets/img/img_me_membervip_card_no_pad.png'">
        <div class="w-91 cardname">
        <div class=" d-flex align-content-center justify-content-between">
            <div class="d-flex">
              <!--已登入-->   
              <div class=" me-1 me-md-2 " v-show="$GlobalData.uid != ''" @click="openShare()">
                <h6 class="text-white text-card-name text-bolder mb-0 ">{{name}}</h6>
                <h5 class="text-white text-card-small mb-0 mt-1 mt-md-2 text-simbold">推薦碼 {{shareCode}}</h5>
              </div>
              <span class="badge badge-danger nicolebadge rounded-pill text-xs " v-show="englishLevelName != ''">{{englishLevelName}}</span>
              <!--未登入-->              
              <div class=" me-1 me-md-2" v-show="$GlobalData.uid == ''" @click="openLogin">
                <h6 class="text-white text-card-big text-bolder mb-0 ">立即登入 <i class="far fa-chevron-right text-sm mt-1 me-1 text-white text-card-big"></i></h6>
                <h5 class="text-white text-sm mb-0 mt-1 mt-md-2 text-simbold">登入會員即享免費課程體驗～</h5>
              </div>
            </div>
           <img class=" avatar rounded-circle avatar-nicole-m" v-real-img="icon" src="assets/img/camera-no.png">  
           </div>
         <h5 class="mb-0 text-card-small text-dark my5min" @click="onUpgradeVip" v-show="!isMonthlyUser && $GlobalData.uid != ''">升級為 VIP 會員，解鎖更多課程！ &nbsp;</h5>
        </div> 
    </div> 
    <!--已登入-->   
    <div class="position-relative px-1 mt-1" v-if="$GlobalData.uid != ''">
     <img class="w-100 d-md-none" src="assets/img/5min_2.png">  
      <img class="w-100 d-none d-md-block pe-2" src="assets/img/5min@2x.png">    
      <div class="card5m w-90" @click="onLevelTest"> 
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0 text-card-small text-dark ">5 分鐘測試你的英語水平 &nbsp;</h5>
          <i class="far fa-chevron-right text-sm mt-1 text-dark"></i>
          </div>
      </div>
    </div>

    </div>
    
    <section class="pt-1 pb-4">
    <div class="container">
        <div class="col-12">
          <div class="d-flex mt-3 mt-md-4">
            <div class="col-4 pt-3" @click="onHistory">
              <div class="text-center">
                <div class="icon icon-shape text-center avatar avatar-nicole-sm">
                  <img src="assets/img/ic_note_black_32.png">
                </div>
                <h6 class="text-sm ">學習記錄</h6>
            </div>
            </div>
            <div class="col-4 pt-3" @click="onCollect">
              <div class="text-center">
                <div class="icon icon-shape text-center avatar avatar-nicole-sm">
                  <img src="assets/img/ic_me_fav_32.png" >
                </div>
                <h6 class="text-sm ">我的收藏</h6>
                    
            </div>
            </div>           
            <div class="col-4 pt-3" @click="onLesson">
              <div class="text-center">
                <div class="icon icon-shape text-center avatar avatar-nicole-sm">
                  <img src="assets/img/ic_me_myprogram_32.png" >
                </div>
                <h6 class="text-sm ">我的課程</h6>
            </div>
            </div>
          </div>          
            <div class="d-flex mt-3 mt-md-4">
            <div class="col-4 pt-3" @click="onAudio">
              <div class="text-center">
                <div class="icon icon-shape text-center avatar avatar-nicole-sm">
                  <img src="assets/img/ic_me_offlineaudio_32.png" >
                </div>
                <h6 class="text-sm ">離線收聽</h6>
            </div>
            </div>
            <div class="col-4 pt-3 position-relative"  @click="onProfile">
              <div class="text-center ">                 
                <div class="icon icon-shape text-center avatar avatar-nicole-sm ">
                  <img src="assets/img/ic_me_account_32.png" >  <div class="divright2" v-show="false">99+</div>  
                </div>
                <h6 class="text-sm ">會員資料</h6>
            </div>
            </div>           
            <div class="col-4 pt-3  position-relative" @click="onMessage">
              <div class="text-center">
                <div class="icon icon-shape text-center avatar avatar-nicole-sm">
                  <img src="assets/img/ic_me_notice_32.png" ><div class="divright" v-show="messageCount > 0">{{(messageCount > 99)? '99+':messageCount}}</div> 
                </div>
                <h6 class="text-sm ">訊息中心</h6>
            </div>
            </div>
          </div>            
            <div class="d-flex mt-3 mt-md-4">
            <div class="col-4 pt-3" @click="onQuestion">
              <div class="text-center">
                <div class="icon icon-shape text-center avatar avatar-nicole-sm">
                  <img src="assets/img/ic_me_qhistery_32.png" >
                </div>
                <h6 class="text-sm ">發問紀錄</h6>
            </div>
            </div>
            <div class="col-4 pt-3" @click="onOrder">
              <div class="text-center">
                <div class="icon icon-shape text-center avatar avatar-nicole-sm">
                  <img src="assets/img/ic_me_purchaseorder_32.png" >
                </div>
                <h6 class="text-sm ">訂購紀錄</h6>
            </div>
            </div>           
            <div class="col-4 pt-3" @click="onRecommendFriend">
              <div class="text-center">
                <div class="icon icon-shape text-center avatar avatar-nicole-sm">
                  <img src="assets/img/friend.png" >
                </div>
                <h6 class="text-sm ">推薦好友</h6>
            </div>
            </div>
          </div>
      </div>
    </div>
  </section>
    <section class="mb-5 mt-md-4">
    <div class="container container-sm">
        <ul class="list-group ">
          <li class="list-group-item border-0  text-dark mb-2 d-flex align-content-center  justify-content-between" @click="onRecommendEvaluation"> 
              <div class="text-s"><img src="assets/img/img005.png" class="avatar avatar-sm rounded-0">
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="' &nbsp;好評推薦'"></span>
              <!--eslint-enable-->
              </div>
              <i class="far fa-chevron-right text-muted text-sm"></i>
          </li>           
            <li class="list-group-item border-0  text-dark mb-2 d-flex align-content-center  justify-content-between" @click="onCommonQuestion"> 
              <div class="text-s"><img src="assets/img/ic_me_q_18.png" class="avatar avatar-sm rounded-0">
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="' &nbsp;常見問題'"></span>
              <!--eslint-enable-->
              </div>
              <i class="far fa-chevron-right text-muted text-sm"></i>
          </li>         
            <li class="list-group-item border-0  text-dark mb-2 d-flex align-content-center  justify-content-between" @click="onContact"> 
                <div class="text-s"><img src="assets/img/ic_me_cs_32.png" class="avatar avatar-sm rounded-0">
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="' &nbsp;聯絡客服'"></span>
              <!--eslint-enable-->
              </div>
               <i class="far fa-chevron-right text-muted text-sm"></i>
          </li>
          <li class="list-group-item border-0  text-dark mb-2 d-flex align-content-center  justify-content-between" @click="onPrivacy"> 
              <div class="text-s"><img src="assets/img/ic_me_pp_18.png" class="avatar avatar-sm rounded-0">
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="' &nbsp;隱私權政策'"></span>
              <!--eslint-enable-->
              </div>
              <i class="far fa-chevron-right text-muted text-sm"></i>
          </li>          
            <li class="list-group-item border-0  text-dark mb-2 d-flex align-content-center  justify-content-between mb-7" @click="onTerms"> 
                <div class="text-s"><img src="assets/img/ic_me_userstrategy_18.png" class="avatar avatar-sm rounded-0">
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="' &nbsp;用戶條款'"></span>
              <!--eslint-enable-->
              </div>
               <i class="far fa-chevron-right text-muted text-sm "></i>
          </li>
        </ul>
    </div>
  </section>  
  </body>
  <Footer/>
</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CommonAlert2Func from '@/components/CommonAlert2Func.vue'


import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import { config } from "@/include/config"; 


export default {
    name: 'Mine',
    components: {
        Header,
        Footer,
        CommonAlert2Func,
    },
    data () {
      return { 
        // api setting
        setDebugMode:true ,
        apiDebug: false,
        apiName: "",

        // data
        user: [],
        title: "",
        content: "",
        btnNameUp: "",
        btnNameDown: "",
        onFuncUp: Function,
        onFuncDown: Function,


        shareCode: "",
        name: "Student",
        englishLevelName: "",
        isLevelTest: "",
        isVisitor: true,
        isMonthlyUser: false,
        ulid:"",
        elid:"",
        icon:"",
        timer: null,
        messageCount: 0,
        backUrl: "",
        openUrl: "",
        isLoading: false,
      }
    },
    methods: {
      setOnBack(){
          this.$JsBridge.callHandler('setOnback', '', this.onBack);
      },
      onBack(){    
          
      },
      openLogin(){
        if(this.openUrl == "" || this.openUrl == undefined){
          this.openUrl = "Mine";
        }
        this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:this.openUrl, type:"",skipBack:"Mine"}), this.openLoginCallback);
        this.backUrl = "";
        this.openUrl = "";
      },
      openWebLogin(){
        if(!this.isLoading){
          this.$JsBridge.callHandler('openWebLoginView',JSON.stringify({backUrl:encodeURIComponent(btoa(this.backUrl)), type:"",skipBack:"Mine",skipBackMode:"vue"}), this.openWebLoginViewCallback);
          this.backUrl = "";
        }
      },
      openLoginCallback(){

      },
      openWebLoginViewCallback(){

      },
      openLoginAlert(funcUp){
          this.title = '完成註冊';
          this.content = '為你留下學習的紀錄';
          this.btnNameUp = '馬上註冊';
          this.btnNameDown = '繼續逛逛';
          this.onFuncUp = funcUp;          
          this.$refs.commonAlert2Func.onShow();
      },
      onHistory(){
        if(!this.isLoading){
          if(!this.isVisitor){
            this.$router.push(
            {
                name : 'LearningRecord',
            });
          }else{
            this.openUrl = "LearningRecord";
            this.openLoginAlert(this.openLogin);
          }
        }
      },
      onCollect(){
        if(!this.isLoading){
          if(!this.isVisitor){          
            this.$router.push(
            {
                name : 'Collect',
            });
          }else{
            this.openUrl = "Collect";
            this.openLoginAlert(this.openLogin);
          }
        }
      },
      onLesson(){ 
        if(!this.isLoading){
          if(!this.isVisitor){
            this.$router.push({
              name: 'MyCoursesCustom',
              params: {
                back: "Mine"
              }
            }); 
		
          }else{
            this.openUrl = "MyCoursesCustom";
            this.openLoginAlert(this.openLogin);
          }
        }
		
		
      },
      onAudio(){
        if(config && config.old_offline_listen && config.old_offline_listen=="Y") {
          if(!this.isLoading){
            if(!this.isVisitor){
              this.$JsBridge.callHandler('openWebView', '/offline_listen', this.changeToWebCallback);
            }else{
              this.backUrl = "/offline_listen";
              this.openLoginAlert(this.openWebLogin);
            }
          }
        } else {
          if(!this.isLoading){
            if(!this.isVisitor){
              this.$JsBridge.callHandler('openOfflineListen',JSON.stringify({
                  backUrl: `Mine`,
                  mode: "vue",
                  type: "open",
              }), this.openOfflineListenCallback);
            }else{
              this.openUrl = "Mine";
              this.backUrl = "";
              this.openLoginAlert(this.openLogin);
            }
          }
        }
      },
      openOfflineListenCallback(){},
      onProfile(){
        if(!this.isLoading){
          if(!this.isVisitor){
            console.log("11111111111111111");
            this.$router.push({
              name: 'MemberProfile',
              params: {
                back: "Mine"
              }
            });
          }else{
            console.log("22222222222222222");
            this.openUrl = "MemberProfile";
            this.openLoginAlert(this.openLogin);
          }
        }
      },
      onMessage(){  
        if(!this.isLoading){      
          this.$router.push({
            name: 'MessageList',
            params: {
              back: "Mine"
            }
          });
        }
      },
      onQuestion(){
        if(!this.isLoading){
          if(!this.isVisitor){
            this.$router.push({
              name: 'QuestionRecordList',
              params: {
                back: "Mine"
              }
            });
          }else{
            this.openUrl = "QuestionRecordList";
            this.openLoginAlert(this.openLogin);
          }
        }
      },
      onOrder(){ 
		
        if(!this.isLoading){
          if(!this.isVisitor){
            this.$router.push({
              name: 'OrderRecord',
              params: {
                back: "Mine"
              }
            }); 
		
          }else{
            this.openUrl = "OrderRecord";
            this.openLoginAlert(this.openLogin);
          }
        }
		
		
      },
      onRecommendFriend(){
	  
	  /*
        if(!this.isLoading){
          if(!this.isVisitor){
            var text = "HI ！推薦你一個我覺得超棒的英文學習APP。\n每天三十分鐘，啟動你的英語外掛人生！\nCNN官方正式授權X張維英文師資團隊 ~\n「輕鬆讀英語新聞」APP，帶你一起學好英文，一邊拓展國際視野！\nAPP下載點 ：\nhttps://zen-test.onelink.me/tDmU/e90fa929";
            this.$JsBridge.callHandler('openShare',JSON.stringify({imageUrl:"", msgText: text, msgTitle:"分享邀請碼"}), this.openShareCallback);
          }else{ 			
            this.openUrl = "Mine";//回到新版Mine
            this.openLoginAlert(this.openLogin);
          }
        }*/
		
		 //ZEN-2759
        if(!this.isLoading){
          if(!this.isVisitor){
            this.$router.push({
              name: 'RecommendFriend',
              params: {
                back: "Mine"
              }
            });
          }else{
            this.openUrl = "RecommendFriend";
            this.openLoginAlert(this.openLogin);
          }
        }
      },
      onRecommendEvaluation(){
        if(!this.isLoading){
          if(!this.isVisitor){
            this.$JsBridge.callHandler('openStore', "", this.openShareCallback);
          }else{      
            this.openUrl = "Mine";//回到新版Mine
            this.openLoginAlert(this.openLogin);
          }
        }
      },      
      onContact(){
        if(!this.isLoading){
          if(!this.isVisitor){
            this.$router.push({
              name: 'ContactService',
              params: {
                back: "Mine"
              }
            });
          }else{
            this.openUrl = "ContactService";
            this.openLoginAlert(this.openLogin);
          }
        }
      },
      onCommonQuestion(){
	  
	  
        if(!this.isLoading){   	 
		      this.$router.push({
            name: 'CommonQuestion',
            params: {
              back: "Mine"
            }
          });
        }
		
       // if(!this.isLoading){
       //   this.$JsBridge.callHandler('openWebView', '/common_question', this.changeToWebCallback);
      //  }
      },
      onPrivacy(){
        if(!this.isLoading){
          this.$router.push({
            name: 'PrivacyPolicy',
            params: {
              back: "Mine"
            }
          });
        }
      },
      onTerms(){            
        if(!this.isLoading){
          this.$router.push({
            name: 'UserTerms',
            params: {
              back: "Mine"
            }
          });
        }
      },
      onSetting(){
	  
        if(!this.isLoading){
          if(!this.isVisitor){
            this.$router.push({
              name: 'Setting',
              params: {
                back: "Mine"
              }
            }); 
		
          }else{
            this.openUrl = "Setting";
            this.openLoginAlert(this.openLogin);
          }
        }
		
		
      },
      onLevelTest(){
        if(!this.isLoading){
          this.$router.push({
            name: 'LevelExamIntro',
            params: {
              back: "Mine"
            }
          });
        }
      },
      onSetUpdateMessageTimer(){
        this.timer = setInterval(() => {
          this.onUpdateMessage();
        }, 10000);
      },
      onUpdateMessage(){
        this.apiName = "page_mine_message";
        oAC.getAllRecord(oAC.getApiParam(this), {uid:this.$GlobalData.uid}).then(
        res => 
            {
                if(res && res.count && res.count > 0){                        
                    this.messageCount = res.count;
                }else{
                    this.messageCount = 0;
                }
            }
        )
        .catch((err)=> {
            this.messageCount = 0;
            oltConsole.log(err);
        });
      },
      changeToWebCallback(data){

      },
      onUpgradeVip(){
        if(!this.isLoading){
          if(!this.isVisitor){
            this.$router.push({
              name: 'UpgradeVip',
              params: {
                backUrl: "Mine"
              }
            });
          }else{
            this.openUrl = "UpgradeVip";
            this.openLoginAlert(this.openLogin);
          }
        }
      },
      gotourl(name){
        if(!this.isLoading){
          if (this.$GlobalData.uid == '') {
            $('#notice1').modal('show'); // 手動關閉 
            return;
          }
          if(name == this.$route.name) return;
          this.$router.push(name);
        }
      },
      openShare(){
        if(!this.isLoading){
          if(!this.isVisitor){
            var text = "HI ！推薦你一個我覺得超棒的英文學習APP。\n每天三十分鐘，啟動你的英語外掛人生！\nCNN 官方正式授權 X 張維英文師資團隊，「輕鬆讀‧英語新聞」APP，\n帶你一起學好英文，一邊拓展國際視野！\n現在輸入我的付費會員推薦序號\n「"+this.shareCode+"」訂閱年會員，我們都能獲得 100 元禮券！立即下載免費體驗！\nAPP下載點 ：\nhttps://zen-test.onelink.me/tDmU/e90fa929";
            this.$JsBridge.callHandler('openShare',JSON.stringify({imageUrl:"", msgText: text, msgTitle:"分享邀請碼"}), this.openShareCallback);
          }else{ 			
            this.openUrl = "Mine";//回到新版Mine
            this.openLoginAlert(this.openLogin);
          }
        }
	  /*
        if(!this.isLoading){
          if(!this.isVisitor){
            var text = "快來加入輕鬆讀英語新聞，使用我的推薦碼：" + this.shareCode + "，完成年會員的訂閱，即可一同獲得推薦好禮! https://zen-test.onelink.me/tDmU/e90fa929";
            this.$JsBridge.callHandler('openShare',JSON.stringify({imageUrl:"", msgText: text, msgTitle:"分享邀請碼"}), this.openShareCallback);
          }else{
            this.backUrl = "Mine";
            this.openLoginAlert(this.openWebLogin);
          }
        }*/
      }, 
      openShareCallback(){
        
      },
      JumpToTop(){
        // 自動將畫面移動到最上方
        $("html, body").animate({ scrollTop: $("html").offset().top }, {duration: 500,easing: "swing"});
      }
    },
    created(){  
      if (this.$JsBridge.isAndroid) {
          this.setOnBack();
      }
      this.JumpToTop();
      this.isLoading = true; 
      if (this.$route.query.uid != undefined) {
        this.$GlobalData.uid = this.$route.query.uid;
      }
      if(this.$GlobalData.uid != "" && this.$GlobalData.user.rt.user){
        this.shareCode = this.$GlobalData.user.rt.user[0].share_code;
        this.apiName = "page_mine";
        oAC.getAllRecord(oAC.getApiParam(this), {uid:this.$GlobalData.uid}).then(
        res => 
            {
                if(res && res.user){                        
                    this.user = res.user;
                    if(res.user[0].name != null && res.user[0].name != "" && res.user[0].name != undefined){
                      this.name = res.user[0].name;
                    }else{
                      this.name = "Student";
                    }
                    
                    this.ulid = res.user[0].ulid;
                    this.elid = res.user[0].elid;
                    this.englishLevelName = res.user[0].english_level_name;
                    this.isLevelTest = res.user[0].level_test_count;
                    this.isVisitor = (this.ulid == "" || this.ulid == this.$GlobalData.user_level_level1);
                    this.isMonthlyUser = (this.ulid == this.$GlobalData.user_level_level4);
                    this.onUpdateMessage();
                    this.onSetUpdateMessageTimer();
                    if(res.user[0].image_url != ""){
                      this.apiName = "page_mine_icon";
					  
			if(config && config.old_images && config.old_images=="Y") 
			{  		
                      oAC.getAllRecord(oAC.getApiParam(this), {url:res.user[0].image_url}).then(
                      res => 
                          {
                              if(res && res.image && res.image != ""){                        
                                  this.icon = 'data:image/jpeg;base64, '.res.image;
                              }else{
                                  this.icon = "";
                              }
                          }
                      )
                      .catch((err)=> {
                          this.icon = "";
                          oltConsole.log(err);
                      });   
			} 
			else 
			{  
			  this.icon = res.user[0].image;
			} 
                    }
                }else{
                    this.user = [];
                }
                this.isLoading = false; 
            }
        )
        .catch((err)=> {
            this.user = [];
            oltConsole.log(err);
            this.isLoading = false; 
        });
      }else{
        this.isLoading = false; 
      }
    }
};
</script>